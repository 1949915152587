import ImageOpt from './ImageOpt';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const customStylesBox = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: '0',
    borderRadius: '0',
    padding: '0',
    width: '100%',
    height: '100%',
    zindex: '999',
  },
};

const itemsPerRow = 6;

const TestimonialListing = ({ module }) => {
  const [next, setNext] = useState(itemsPerRow);
  const [popupContent, setPopupContent] = useState(null);
  const [isPopupOpen, setPopupOpenStatus] = useState(false);

  const handleMoreItems = () => {
    setNext(next + itemsPerRow);
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    } else {
      return text;
    }
  }

  const openPopupContent = (content) => {
    document.body.classList.add('video-modal-open');
    setPopupContent(content);
    setPopupOpenStatus(true);
  };

  const closePopup = () => {
    setPopupOpenStatus(false);
    document.body.classList.remove('video-modal-open');
  };

  const query = useStaticQuery(graphql`
    query {
      grid: allWpTestimonial(sort: { fields: [date], order: DESC }) {
        nodes {
          title
          uri
          content
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          ... on WpTestimonial {
            testimonialContent {
              gallery {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `);

  const testimonials = query?.grid?.nodes || [];

  return (
    <>
      <section
        className={`small-img-grid with-pattern lg:py-120 py-30${
          module?.extraClass ? ' ' + module.extraClass : ''
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap lg:mx-minus-30 mx-0 gap-y-14 mdscreen:gap-8">
            {testimonials?.length > 0 &&
              testimonials.slice(0, next).map((testimonialItem, index) => (
                <div className="lg:w-4/12 w-full lg:px-15 px-0 fade-ani">
                  <div className="ig-bx">
                    <ImageOpt
                      imgData={testimonialItem.featuredImage?.node.mediaItemUrl}
                      imgAlt={testimonialItem.featuredImage?.node?.altText}
                      imgClass="w-full h-[300px] object-cover"
                      width={432}
                      height={420}
                    />
                    <div className="ig-info p-30 lgsccreen:p-15">
                      <div className="title-black">
                        {testimonialItem?.title && (
                          <h6 className="">{testimonialItem.title}</h6>
                        )}
                        {testimonialItem?.content && (
                          <div className="content ">
                            {parse(truncateText(testimonialItem.content, 200))}
                          </div>
                        )}
                        {testimonialItem?.uri && (
                          <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20">
                            <span
                              onClick={() => openPopupContent(testimonialItem)}
                              className="button btn-red-border cursor-pointer"
                            >
                              Read More
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {next < testimonials?.length && (
            <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-70 mt-30 fade-ani">
              <button className="button btn-border" onClick={handleMoreItems}>
                <span>Load More testimonials</span>
                <img
                  src="/images/btn-red-bg.png"
                  loading="eager"
                  alt="Load More Button"
                />
              </button>
            </div>
          )}
        </div>
      </section>
      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
        style={customStylesBox}
      >
        <div className="zigzag-modal-popup bg-pattern2">
          <button
            onClick={closePopup}
            className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 inline-block z-99"
          >
            <img
              src="/images/modal-close.png"
              loading="lazy"
              className="m-auto w-20 h-20"
              alt="Close"
            />
          </button>
          <div className="flex flex-wrap items-center">
            <div className="lg:w-5/12 w-full">
              <div className="img-slider">
                {popupContent?.testimonialContent?.gallery &&
                popupContent.testimonialContent.gallery.length > 0 ? (
                  <Swiper
                    spaceBetween={0}
                    loop={false}
                    slidesPerView={1}
                    allowTouchMove={true}
                    grabCursor={true}
                    speed={1000}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                  >
                    {popupContent.testimonialContent.gallery.map(
                      (image, index) => (
                        <SwiperSlide key={index}>
                          <ImageOpt
                            imgData={image?.mediaItemUrl}
                            width={600}
                            height={800}
                            imgClass="mdscreen:h-[90vw] w-full object-cover"
                            imgAlt={image?.altText}
                          />
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                ) : (
                  <ImageOpt
                    imgData={popupContent?.featuredImage?.node.mediaItemUrl}
                    width={600}
                    height={800}
                    imgClass="mdscreen:h-[90vw] w-full object-cover"
                    imgAlt={popupContent?.featuredImage?.node.altText}
                  />
                )}
              </div>
            </div>
            <div className="lg:w-7/12 w-full">
              <div className="zigzag-modal-popup-content lg:px-100 px-30 lg:pt-0 py-30">
                <span className="text-red-300 uppercase text-15 mb-15 inline-block w-full">
                  Guest Feedback
                </span>
                {popupContent?.title && (
                  <div className="title-black">
                    <h3>{popupContent?.title}</h3>
                  </div>
                )}
                {popupContent?.content && (
                  <div className="content global-list">
                    {parse(popupContent.content)}
                  </div>
                )}
                <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9 ">
                  <Link to="/contact-us/" className="button header-btn-red">
                    <span>Get in Touch</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TestimonialListing;
export const TestimonialListingFragment = graphql`
  fragment TestimonialListingFragment on WpPage_Pagecontent_PageContent_TestimonialListing {
    id
    extraClass
    hideSection
  }
`;
